export const availableRoles = [
  { value: "beneficiary_create", label: "Create Beneficiary" },
  { value: "beneficiary_view", label: "View Beneficiary" },
  { value: "beneficiary_disable", label: "Archive Beneficiary" },
  { value: "payment_create", label: "Create Payment" },
  { value: "payment_view", label: "View Payment" },
  { value: "payment_cancel", label: "Cancel Payment" },
  { value: "quote", label: "Quote" },
  { value: "users_view", label: "View Users" },
  { value: "invite", label: "Invite" },
  { value: "grant", label: "Grant" },
  { value: "revoke", label: "Revoke" },
  { value: "approve_payment", label: "Approvals" },
];

export const roleMapping = {
  approve_payment: "approve::payment",
  payment_cancel: "payment::cancel",
  payment_view: "payment::view",
  beneficiary_view: "beneficiaries::view",
  users_view: "users::view",
};

export const groupedRoles = {
  Beneficiary: [
    { value: "beneficiary_create", label: "Create Beneficiary" },
    { value: "beneficiary_view", label: "View Beneficiary" },
    { value: "beneficiary_disable", label: "Archive Beneficiary" },
  ],
  Payment: [
    { value: "payment_create", label: "Create Payment" },
    { value: "payment_view", label: "View Payment" },
    { value: "payment_cancel", label: "Cancel Payment" },
    { value: "quote", label: "Quote" },
    { value: "approve_payment", label: "Approvals" },
  ],
  User: [
    { value: "users_view", label: "View Users" },
    { value: "invite", label: "Invite" },
    { value: "grant", label: "Grant" },
    { value: "revoke", label: "Revoke" },
  ],
};
