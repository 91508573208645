import React, { createContext, useEffect, useState, useContext } from "react";
import axios from "axios";
import ConfigContext from "../store/context/ConfigContext";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [jwtToken, setJwtToken] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userSub, setUserSub] = useState(null);
  const config = useContext(ConfigContext);
  let refreshTimer;

  useEffect(() => {
    const storedToken = sessionStorage.getItem("jwtToken");
    if (storedToken) {
      setJwtToken(storedToken);
      setIsAuthenticated(true);
      parseJwt(storedToken);
    }
  }, []);

  function handleLogout() {
    window.location.assign("/logout");
  }

  const parseJwt = (token) => {
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(""),
      );
      const { sub, exp } = JSON.parse(jsonPayload);
      if (sub) {
        setUserSub(sub);
      } else {
        console.error("Sub claim is missing in JWT.");
      }
      scheduleTokenRefresh(exp);
    } catch (error) {
      console.error("Error parsing JWT", error);
    }
  };

  const scheduleTokenRefresh = (expiryTime) => {
    const currentTime = Math.floor(Date.now() / 1000);
    const timeUntilExpiry = expiryTime - currentTime;

    // a minute before expiry it will refresh
    const refreshTime = timeUntilExpiry - 60;

    if (refreshTime > 0) {
      refreshTimer = setTimeout(() => {
        refreshToken();
      }, refreshTime * 1000);
    } else {
      console.log("token expired");
    }
  };

  const setAuthToken = (token) => {
    setJwtToken(token);
    sessionStorage.setItem("jwtToken", token);
    parseJwt(token);
  };

  const removeAuthToken = () => {
    sessionStorage.clear();
    setJwtToken(null);
    setIsAuthenticated(false);
    setUserSub(null);
    if (refreshTimer) {
      clearTimeout(refreshTimer);
    }
  };

  const handleTokenExpiry = () => {
    window.location.assign("/token-expiry");
  };

  const refreshToken = async () => {
    try {
      const jwtToken = sessionStorage.getItem("jwtToken");
      const authHeaders = {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        Figaro: config.API_REQUEST_HEADER,
      };
      const response = await axios.post(
        `${config.API_URL}/login/v1/refresh`,
        {},
        { headers: authHeaders },
      );

      if (response.data && response.data.jwt) {
        setAuthToken(response.data.jwt);

        if (response.data.realms && response.data.realms.length > 0) {
          const firstRealm = response.data.realms[0];
          const roles = firstRealm.roles.map((role) => role.name);
          sessionStorage.setItem("roles", JSON.stringify(roles));
        }
      } else {
        handleLogout();
      }
    } catch (error) {
      console.error("Error refreshing token", error);
      handleLogout();
    }
  };

  return (
    <AuthContext.Provider
      value={{
        jwtToken,
        setAuthToken,
        removeAuthToken,
        isAuthenticated,
        setIsAuthenticated,
        handleTokenExpiry,
        userSub,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
