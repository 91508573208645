import React, { useState, useEffect } from "react";

type Props = {
  label: string;
  completeLabel: string;
  endTimeInMs: number;
};

//TODO: Move to core-ui
const CountdownTimer = ({ label, completeLabel, endTimeInMs }: Props) => {
  const timeRemainingFromNow = endTimeInMs - Math.round(Date.now() / 1000);
  const [timeRemaining, setTimeRemaining] = useState(timeRemainingFromNow);

  useEffect(() => {
    let timer = setInterval(() => {
      setTimeRemaining((time) => {
        if (time === 0) {
          clearInterval(timer);
          return 0;
        } else return time - 1;
      });
    }, 1000);
  }, []);

  if (isNaN(timeRemaining)) {
    return;
  }

  if (timeRemaining < 0) {
    return <>{`(${completeLabel})`}</>;
  }

  // Convert seconds to hours, minutes, and seconds
  const hours = Math.floor(timeRemaining / 3600);
  const minutes = Math.floor((timeRemaining % 3600) / 60);
  const seconds = timeRemaining % 60;

  return <>{`(${label} ${hours}h ${minutes}m ${seconds}s)`}</>;
};

export default CountdownTimer;
