import React, { useContext, useState } from "react";
import MainCard from "../ui-component/cards/MainCard";
import RolesDataGrid from "../ui-component/roles-table/RolesDataGrid";
import RoleSelectionModal from "../ui-component/roles-table/RolesSelectionModal";
import UserRolesSettingsModal from "../ui-component/roles-table/UserRolesSettingsModal";
import { RolesContext } from "../store/context/RolesContext";
import { useNavigate } from "react-router-dom";
import ErrorToast from "../ui-component/alerts/ErrorToast";
import SuccessToast from "../ui-component/alerts/SuccessToast";

// ============================|| USER TABLE DISPLAY ||============================ //
const RolesList = () => {
  const [isGrantModalOpen, setIsGrantModalOpen] = useState(false);
  const [isRevokeModalOpen, setIsRevokeModalOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const { roles } = useContext(RolesContext);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleSuccessToastClose = () => {
    setShowSuccessToast(false);
  };
  const handleErrorToastClose = () => {
    setShowErrorToast(false);
  };

  if (
    !roles.includes("grant") &&
    !roles.includes("revoke") &&
    !roles.includes("invite")
  ) {
    navigate("/");
  }

  const handleSuccess = (message) => {
    setSuccessMessage(message);
    setShowSuccessToast(true);
  };

  const handleError = (message) => {
    setErrorMessage(message);
    setShowErrorToast(true);
  };

  const handleGrantClick = (userData) => {
    setSelectedUser(userData);
    setIsGrantModalOpen(true);
  };
  const handleRevokeClick = (userData) => {
    setSelectedUser(userData);
    setIsRevokeModalOpen(true);
  };
  const handleUserSettingsClick = (userData) => {
    setSelectedUser(userData);
    setIsSettingsModalOpen(true);
  };
  const handleGrantCloseModal = () => {
    setIsGrantModalOpen(false);
    setSelectedUser(null);
  };
  const handleRevokeCloseModal = () => {
    setIsRevokeModalOpen(false);
    setSelectedUser(null);
  };
  const handleSettingsCloseModal = () => {
    setIsSettingsModalOpen(false);
    setSelectedUser(null);
  };
  return (
    <MainCard title={""} content={false}>
      <RolesDataGrid
        onEditClick={handleGrantClick}
        onRevokeClick={handleRevokeClick}
        onSettingsClick={handleUserSettingsClick}
      />
      {isGrantModalOpen && (
        <RoleSelectionModal
          open={isGrantModalOpen}
          onClose={handleGrantCloseModal}
          user={selectedUser}
          actionType={"grant"}
        />
      )}
      {isRevokeModalOpen && (
        <RoleSelectionModal
          open={isRevokeModalOpen}
          onClose={handleRevokeCloseModal}
          user={selectedUser}
          actionType={"revoke"}
        />
      )}
      {isSettingsModalOpen && (
        <UserRolesSettingsModal
          open={isSettingsModalOpen}
          onClose={handleSettingsCloseModal}
          user={selectedUser}
          handleSuccess={handleSuccess}
          handleError={handleError}
        />
      )}
      <ErrorToast
        open={showErrorToast}
        onClose={handleErrorToastClose}
        message={errorMessage}
      />
      <SuccessToast
        open={showSuccessToast}
        onClose={handleSuccessToastClose}
        message={successMessage}
      />
    </MainCard>
  );
};

export default RolesList;
