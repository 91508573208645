import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import App from "App";
import { store } from "store";
import "assets/scss/style.scss";
import "react-toastify/dist/ReactToastify.css";
import ConfigContext from "./store/context/ConfigContext";
import { BeneficiaryProvider } from "./store/context/EntityContext";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import { ClientAccountProvider } from "./store/context/ClientAccountContext";
import { RolesProvider } from "./store/context/RolesContext";
import { AuthProvider } from "./auth/AuthContext";

function Main() {
  const [config, setConfig] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetch("/webConfig.json")
      .then((response) => response.json())
      .then((data) => setConfig(data))
      .catch((error) => {
        console.error("Error fetching the config:", error);
        setError(true);
      });
  }, []);

  if (error) {
    return <div>Error: Config is missing.</div>;
  }

  if (!config) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Helmet>
        <title>{config.SEO}</title>
        <meta name="description" content={config.SEO} />
        <link rel="icon" href={config.FAVICON_URL} />
      </Helmet>
      <ConfigContext.Provider value={config}>
        <AuthProvider>
          <ClientAccountProvider>
            <RolesProvider>
              <BeneficiaryProvider>
                <ToastContainer />
                <Provider store={store}>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </Provider>
              </BeneficiaryProvider>
            </RolesProvider>
          </ClientAccountProvider>
        </AuthProvider>
      </ConfigContext.Provider>
    </>
  );
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<Main />);
