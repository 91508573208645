import React, {useEffect, useState} from "react";
import {Form} from "formik";
import {Button, Grid, IconButton, Typography} from "@mui/material";
import LedgerDropdown from "../ui-component/ledger/LedgerDropdown";
import CustomCurrencyValueInput from "../ui-component/form-fields/CustomCurrencyValueInput";
import CustomFormField from "../ui-component/form-fields/CustomFormField";
import OtpInput from "react18-input-otp";
import {formatDateToMonthDayYearWithTime} from "../common/getDate";
import PropTypes from "prop-types";
import {DatePicker} from "@mui/x-date-pickers";
import {Refresh} from "@mui/icons-material";


//----------------------------  Custom Date Picker Styles Toolbar  --------------------------------------//
const datePickerStyle = {
    mt: 1.25,
    '& .MuiInputBase-input': {backgroundColor: 'white'},
    '& .MuiInputBase-root': {
        height: 60, backgroundColor: 'white',
        '&.Mui-focused': {backgroundColor: 'white'},
        '&:hover': {backgroundColor: 'white'},
    },
    '& .MuiIconButton-root': {
        backgroundColor: 'white',
        '&:hover': {backgroundColor: 'white'},
    },
    '& .MuiOutlinedInput-notchedOutline': {borderColor: 'rgba(0, 0, 0, 0.23)'},
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'theme.palette.primary.main',
    },
};

//---------------------------- | Transfer Form | --------------------------------------//

const TransferForm = ({
                          values,
                          setFieldValue,
                          isSubmitting,
                          touched,
                          errors,
                          handleSubmit,
                          showFetchRateButton,
                          setShowFetchRateButton,
                          handleFetchRate,
                          handleChange,
                          handleBlur,
                          showTotpInput,
                          localTotp,
                          setLocalTotp,
                          rate,
                          apiResponse,
                          fromCurrency,
                          setFromCurrency,
                          toCurrency,
                          setToCurrency,
                          selectedDate,
                          setSelectedDate,
                          isSubmitted,
                          apiError
                      }) => {
    const [openDatePicker, setOpenDatePicker] = useState(false);

    useEffect(() => {
        if (fromCurrency && toCurrency && fromCurrency !== toCurrency && values.amount) {
            setShowFetchRateButton(true);
        } else {
            setShowFetchRateButton(false);
        }
    }, [fromCurrency, toCurrency, values.amount, setShowFetchRateButton]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setFieldValue('settlementDate', date);
    };

    const handleRefresh = () => {
        window.location.reload();
    };
    return (
        <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <LedgerDropdown
                        name="fromLedgerId"
                        label="From Account"
                        setSelectedCurrency={setFromCurrency}
                        defaultCurrency={"USD"}
                        textSize={"1rem"}
                        externalOnChange={(event) => {
                            setFieldValue('fromLedgerId', event.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={10}>
                    <LedgerDropdown
                        name="toLedgerId"
                        label="To Account"
                        setSelectedCurrency={setToCurrency}
                        textSize={"1rem"}
                        externalOnChange={(event) => {
                            setFieldValue('toLedgerId', event.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <CustomCurrencyValueInput
                        name="amount"
                        label={`${fromCurrency} Amount`}
                        value={values.amount}
                        handleChange={(event) => {
                            setFieldValue('amount', event.target.value);
                            setShowFetchRateButton(event.target.value && values.fromLedgerId.currency !== values.toLedgerId.currency);
                        }}
                        touched={touched}
                        errors={errors}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <DatePicker
                        label="Settlement Date"
                        value={selectedDate}
                        onChange={handleDateChange}
                        open={openDatePicker}
                        onOpen={() => setOpenDatePicker(true)}
                        onClose={() => setOpenDatePicker(false)}
                        sx={datePickerStyle}
                    />
                </Grid>
                {showFetchRateButton && (
                    <Grid mt={2.5} item xs={12} sm={4}>
                        <Button
                            onClick={() => handleFetchRate(values)}
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting || !values.amount}
                        >
                            Get Rate
                        </Button>
                    </Grid>
                )}
                {apiResponse && (
                    <Grid mt={1} ml={2} item xs={12}>
                        <Typography variant="h2">FX Rate</Typography>
                        <Typography variant="h5">Rate: {rate}</Typography>
                        <Typography
                            variant="h5">Expiry: {formatDateToMonthDayYearWithTime(apiResponse.response.expiry)}</Typography>
                    </Grid>
                )}
                {apiError && (
                    <Grid mt={1} ml={2} item xs={12}>
                        <Typography variant="h4" sx={{flexGrow: 1, textAlign: "center"}}>
                            Something went wrong, please try again
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <CustomFormField
                        name="reference"
                        label="Reference"
                        value={values.reference}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomFormField
                        name="description"
                        label="Description"
                        value={values.description}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                    />
                </Grid>
                {showTotpInput && (
                    <Grid item xs={12} mb={2} ml={1} mr={1}>
                        <Typography variant={"h4"} sx={{mb: 2}}> Enter 6-digit authentication code:</Typography>
                        <OtpInput
                            value={localTotp}
                            onChange={setLocalTotp}
                            numInputs={6}
                            containerStyle={{justifyContent: 'space-between'}}
                            inputStyle={{
                                width: '100%',
                                margin: '8px',
                                padding: '10px',
                                border: `1px solid `,
                                borderRadius: 4,
                                ':hover': {
                                    borderColor: "#000"
                                }
                            }}
                            focusStyle={{
                                outline: 'none',
                                border: `2px solid #000`
                            }}
                            shouldAutoFocus={true}
                        />
                    </Grid>
                )}
                <Grid item xs={6}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                    >
                        Submit
                    </Button>
                    {isSubmitted && (
                        <IconButton
                            color="secondary"
                            onClick={handleRefresh}
                        >
                            <Refresh/>
                        </IconButton>
                    )}
                </Grid>
            </Grid>
        </Form>
    )
}

TransferForm.propTypes = {
    values: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    showFetchRateButton: PropTypes.bool.isRequired,
    setShowFetchRateButton: PropTypes.func.isRequired,
    handleFetchRate: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    showTotpInput: PropTypes.bool.isRequired,
    localTotp: PropTypes.string.isRequired,
    setLocalTotp: PropTypes.func.isRequired,
    rate: PropTypes.number,
    apiResponse: PropTypes.object,
    fromCurrency: PropTypes.string,
    setFromCurrency: PropTypes.func.isRequired,
    toCurrency: PropTypes.string,
    setToCurrency: PropTypes.func.isRequired,
    selectedDate: PropTypes.func.isRequired,
    setSelectedDate: PropTypes.func.isRequired,
};

export default TransferForm;