import React, { useCallback, useContext, useEffect, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
// material-ui
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// project imports
import ConfigContext from "../../../store/context/ConfigContext";
import { useAuthHeaders } from "../../../auth/AuthHeaders";
import { ApprovalsColumns, ApprovalsColumnsMobile } from "./ApprovalsColumns";
import { ViewApprovalsModal } from "../ViewApprovalsModal";
import { formatDateToMonthDayYearWithTime } from "../../../common/getDate";
import { ApprovalsToolbar } from "./ApprovalsToolbar";
import { getFormattedCurrencyAmountWithName } from "../../../store/getFormattedCurrencyAmount";
import { CustomNoRowsOverlay } from "../../datagrid/CustomEmptyDataTable";

//----------------------------  APPROVALS Data Grid  --------------------------------------//
const ApprovalsDataGrid = ({
  data,
  refreshData,
  dateFilter,
  isShowDateFilter,
  setIsShowDateFilter,
  approvalsUuid,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [tabValue, setTabValue] = useState(0);
  const [reference, setReference] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const config = useContext(ConfigContext);
  const authHeaders = useAuthHeaders();

  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("md"));

  const getRowClassName = (params) => {
    return params.indexRelativeToCurrentPage % 2 === 0 ? "rowEven" : "rowOdd";
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    refreshData(dateFilter.begin, dateFilter.end);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const processData = useCallback(
    (data) => {
      if (!data) return [];

      const formatData = (item, index) => ({
        id: index,
        section: item.section,
        type: item.type,
        client: item.client,
        expiry: formatDateToMonthDayYearWithTime(item.expiry),
        approved: item.number?.approved,
        required: item.number?.required,
        receiverName: item.summary?.receiverName,
        sellCombo: `${getFormattedCurrencyAmountWithName(item.summary?.sellAmount, item.summary?.sellCurrency)}`,
        buyCombo: `${getFormattedCurrencyAmountWithName(item.summary?.buyAmount, item.summary?.buyCurrency)}`,
        buyCurrency: item.summary?.buyCurrency,
        sellCurrency: item.summary?.sellCurrency,
        overallState: item.overallState,
        reference: item.reference,
      });
      const relevantData =
        tabValue === 0 ? data.now : tabValue === 1 ? data.pending : [];
      return relevantData ? relevantData.map(formatData) : [];
    },
    [tabValue],
  );

  const processedData = processData(data);

  const handleRowClick = async (params) => {
    if (tabValue !== 0) {
      return;
    }
    try {
      const response = await axios.post(
        `${config.API_URL}/approvals/v1/get`,
        { reference: params.row.reference },
        { headers: authHeaders },
      );
      setReference(params.row.reference);
      setSelectedRowData(response.data);
      setOpenModal(true);
    } catch (err) {
      console.error(
        "Error occurred while fetching approval details:",
        err.message,
      );
    }
  };

  const handleDateChange = useCallback(
    (fromDate, toDate) => {
      setIsLoading(true);
      refreshData(fromDate, toDate).finally(() => {
        setTimeout(() => setIsLoading(false), 550);
      });
    },
    [refreshData],
  );

  const convertToDate = (date) => {
    return date instanceof Date ? date : date.toDate();
  };

  useEffect(() => {
    const fetchApprovalDetails = async () => {
      if (!approvalsUuid) return;
      try {
        const response = await axios.post(
          `${config.API_URL}/approvals/v1/get`,
          { reference: approvalsUuid },
          { headers: authHeaders },
        );
        setReference(approvalsUuid);
        setSelectedRowData(response.data);
        setOpenModal(true);
      } catch (err) {
        console.error(
          "Error occurred while fetching approval details:",
          err.message,
        );
      }
    };
    fetchApprovalDetails();
  }, []);

  return (
    <div style={{ height: "85vh", width: "100%" }}>
      <DataGrid
        rows={processedData}
        columns={matchesSM ? ApprovalsColumnsMobile : ApprovalsColumns}
        onRowClick={handleRowClick}
        getRowClassName={getRowClassName}
        loading={isLoading}
        slots={{
          toolbar: () => (
            <ApprovalsToolbar
              tabValue={tabValue}
              handleTabChange={handleTabChange}
              dateFilter={{
                begin: convertToDate(dateFilter.begin),
                end: convertToDate(dateFilter.end),
              }}
              isShowDateFilter={isShowDateFilter}
              setIsShowDateFilter={setIsShowDateFilter}
              handleDateChange={handleDateChange}
              loading={isLoading}
            />
          ),
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        sortModel={[
          {
            field: "expiry",
            sort: "desc",
          },
        ]}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[10, 25, 50]}
        sx={{
          "& .rowOdd": {
            backgroundColor: "#ffffff",
            "&:hover": {
              backgroundColor: "#f0f0f0",
            },
          },
          "& .rowEven": {
            backgroundColor: "#fafafa",
            "&:hover": {
              backgroundColor: "#f0f0f0",
            },
          },
        }}
      />
      <ViewApprovalsModal
        open={openModal}
        handleClose={handleCloseModal}
        data={selectedRowData}
        reference={reference}
      />
    </div>
  );
};

ApprovalsDataGrid.propTypes = {
  data: PropTypes.object.isRequired,
  refreshData: PropTypes.func.isRequired,
  dateFilter: PropTypes.shape({
    begin: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.object])
      .isRequired,
    end: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.object])
      .isRequired,
  }).isRequired,
  isShowDateFilter: PropTypes.bool.isRequired,
  setIsShowDateFilter: PropTypes.func.isRequired,
  approvalsUuid: PropTypes.string,
};

export default ApprovalsDataGrid;
