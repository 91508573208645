import React from "react";
import { currencyOptions } from "./CurrencyOptions";
import { CurrencyOption } from "../types";

// ==============================|| GET CURRENCY ICON ||============================== //

export const getCurrencyFlag = (currencyCode: string) => {
  const option = currencyOptions.find(
    (option: CurrencyOption) => option.value === currencyCode,
  );
  return option?.icon || "";
};

// ==============================|| RENDER CURRENCY ICON ||============================== //

const currencyFlagHelper = (currencyOption: string) => {
  const flagPath = getCurrencyFlag(currencyOption);

  return (
    <img
      src={`${flagPath}`}
      alt={`Flag Icon`}
      style={{
        maxHeight: "30px",
        width: "auto",
        objectFit: "contain",
        verticalAlign: "center",
      }}
    />
  );
};

export default currencyFlagHelper;
