/// ==========||  Determine form fields based on locale data ||======== //
export const getFormFields = (countryData) => {
  const fieldConfigs = [
    { key: "phone", label: "Phone Number" },
    { key: "nationalId", label: "National ID" },
    { key: "branch", label: "Branch" },
    { key: "bank", label: "Bank Code" },
    { key: "contact", label: "Contact" },
    { key: "agent", label: "Agent" },
    { key: "lei", label: "LEI" },
  ];
  return fieldConfigs
    .filter((config) => countryData[config.key])
    .map((config) => ({
      name: config.key,
      label: config.label,
      required: true,
    }));
};

export const getPostalCodeField = (countryData) => {
  if (countryData.postalCode) {
    return {
      name: "postalCode",
      label: "Postal Code / Zip Code",
      regex: countryData.postalCode.regex,
      sample: countryData.postalCode.sample,
      required: true,
    };
  }
  return null;
};
