import React, { useContext } from "react";
// 3rd party
import { useLocation, useNavigate, useParams } from "react-router-dom";
// project imports
import ApprovalsDataGrid from "../ui-component/approvals/approvals-table/ApprovalsDataGrid";
import MainCard from "../ui-component/cards/MainCard";
import { RolesContext } from "../store/context/RolesContext";
import useFetchApprovalsData from "../store/hooks/useFetchApprovalsData";

//-------------------------------------APPROVALS VIEW --------------------------------------//

const Approvals = () => {
  const { data, fetchData, dateFilter, isShowDateFilter, setIsShowDateFilter } =
    useFetchApprovalsData("/approvals/v1/list");

  const { roles } = useContext(RolesContext);
  const navigate = useNavigate();
  const { uuid } = useParams();

  if (!roles.includes("approve::payment")) {
    navigate("/");
  }

  return (
    <MainCard title={""} content={false}>
      <ApprovalsDataGrid
        data={data}
        refreshData={fetchData}
        dateFilter={dateFilter}
        isShowDateFilter={isShowDateFilter}
        setIsShowDateFilter={setIsShowDateFilter}
        approvalsUuid={uuid}
      />
    </MainCard>
  );
};

export default Approvals;
