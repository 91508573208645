import { util } from "@wire-fx/core-ui";

const { currency } = util;
const { currencyOptions } = currency;

export const getCurrencies = () => {
  const currencies = sessionStorage.getItem("currencies");
  const defaultCurrencies = ["CAD", "USD", "EUR", "AUD", "GBP"];
  const activeCurrencyCodes = currencies
    ? JSON.parse(currencies)
    : defaultCurrencies;

  // Find and log missing currencies
  const missingCurrencies = activeCurrencyCodes.filter(
    (code) => !currencyOptions.some((option) => option.value === code),
  );
  if (missingCurrencies.length > 0) {
    console.log("Missing currencies from currencyOptions:", missingCurrencies);
  }

  // Filter currencyOptions based on activeCurrencyCodes
  return currencyOptions.filter((option) =>
    activeCurrencyCodes.includes(option.value),
  );
};
