import React, {useContext, useState} from "react";
import * as PropTypes from "prop-types";
import axios from "axios";
import {toast} from "react-toastify";

// material-ui
import {Dialog, DialogContent} from "@mui/material";

// project imports
import ConfigContext from "../../store/context/ConfigContext";
import ApprovalDetailsCard from "./ApprovalDetailsCard";
import {useAuthHeaders} from "../../auth/AuthHeaders";

//-------------------------------------APPROVALS MODAL  --------------------------------------//
export function ViewApprovalsModal({open, handleClose, data, reference}) {
    const [mainError, setMainError] = useState(null);
    const [totpCode, setTotpCode] = React.useState("");
    const [totpError, setTotpError] = useState(null);

    const config = useContext(ConfigContext);
    const authHeaders = useAuthHeaders();

    const closeModalClearErrors = () => {
        setMainError(null);
        setTotpError(null);
        handleClose();
    }

    const sendAcceptRequest = async (note) => {
        try {
            const response = await axios.post(
                `${config.API_URL}/approvals/v1/accept`,
                {   
                    reference: reference, 
                    note: note
                },
                {headers: authHeaders}
            );
            if (response.data && response.data.error) {
                setMainError(response.data.error.message || "Sorry, that didn't work. Please try again or contact support.");
            } else {
            }
        } catch (error) {
            console.error('Error:', error);
            setMainError(`An error has occurred, please try again. Error: ${error.message}`);
        }
    };

    const sendRejectRequest = async (note) => {
        try {
            const response = await axios.post(
                `${config.API_URL}/approvals/v1/reject`,
                {   
                    reference: reference, 
                    note: note
                },
                {headers: authHeaders}
            );
            if (response.data && response.data.error) {
                setMainError(response.data.error.message || "Sorry, that didn't work. Please try again or contact support.");
            } else {
            }
        } catch (error) {
            console.error('Error:', error);
            setMainError(error.message || "Sorry, that didn't work. Please try again or contact support.");

        }
    };

    const handleAcceptRequest = (note) => {
        if (data?.totpRequired) {
            handleTOTPSubmit(totpCode, "accept", note);
        } else {
            sendAcceptRequest(note);
        }
    };

    const handleRejectedRequest = (note) => {
        if (data?.totpRequired) {
            handleTOTPSubmit(totpCode, "reject", note);
        } else {
            sendRejectRequest(note);
        }
    };
    const handleTOTPSubmit = async (code, action, note) => {
        const requestData = {
            reference: reference,
            totp: code,
            note: note
        };

        try {
            const response =
                action === "accept"
                    ? await axios.post(
                        `${config.API_URL}/approvals/v1/accept`,
                        requestData,
                        {headers: authHeaders}
                    )
                    : await axios.post(`${config.API_URL}/approvals/v1/reject`,
                        requestData,
                        {headers: authHeaders}
                    );

            if (response.data.error && response.data.error.code === "InvalidRequestState") {
                toast.error(response.data.error.message, {
                    position: toast.POSITION.BOTTOM_LEFT,
                    theme: "colored",
                });
                handleClose();
            } else if (response.data.error && response.data.error.code === "PermissionDenied") {
                setTotpError("Hmm, looks like the code you entered was either incorrect or you don't have permission to do this action.");
            } else {
                if (response.data.error) {
                    setTotpError(response.data.error.message || "Sorry, that didn't work. Please try again or contact support.");
                } else {
                    closeModalClearErrors();
                }
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };  

    const handleTotpChange = (newTotpCode) => {
        setTotpCode(newTotpCode);
    }


    return (
        <Dialog open={open} onClose={closeModalClearErrors} fullWidth maxWidth="md">
            <DialogContent sx={{backgroundColor: '#f5f5f5', padding: '20px'}}>
                <ApprovalDetailsCard
                    data={data}
                    acceptOnClick={handleAcceptRequest}
                    rejectOnClick={handleRejectedRequest}
                    handleTotpChange={handleTotpChange}
                    loading={false}
                    totpCode={totpCode}
                    mainError={mainError}
                    totpError={totpError}
                />
            </DialogContent>
        </Dialog>
    );
}


ViewApprovalsModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    reference: PropTypes.string.isRequired,
};