import { allCountries } from 'country-region-data';

export const getAllCountries = () => {
  // Filter out the blocked countries
  const blockedCountries = JSON.parse(sessionStorage.getItem("blockedCountries") || '') || [];
  return allCountries
      .filter(countryArray => !blockedCountries.includes(countryArray[1]))
      .map(countryArray => ({
          value: countryArray[1],
          label: countryArray[0]
      }));
}

export const getCountryAndRegion = (countryCode: string, regionCode: string) => {
  const countryData = allCountries.find(country => country[1] === countryCode)
  if (countryData) {
      const region = countryData[2]
        .map(regionArray => ({ label: regionArray[0], value: regionArray[1]}))
        .find(region => region.value === regionCode);
      return {
        label: countryData[0],
        value: countryData[1],
        region: {...region || { label: ''}}
      }
  }
  return {label: countryCode || '', region: {label: regionCode || ''}}
}