import { useContext, useCallback } from "react";
import { useAuthHeaders } from "../../auth/AuthHeaders";
import axios from "axios";
import { toast } from "react-toastify";
import ConfigContext from "../../store/context/ConfigContext";

const useHandleArchive = (
  selectedEntityId,
  totp,
  setBasicModalOpen,
  triggerReload,
) => {
  const config = useContext(ConfigContext);
  const apiUrl = config.API_URL;
  const authHeaders = useAuthHeaders();
  const clientId = sessionStorage.getItem("clientId");

  const handleArchive = useCallback(async () => {
    try {
      const requestData = {
        clientId: clientId,
        totp: totp,
        disable: true,
        request: {
          entityId: selectedEntityId,
        },
      };
      const config = {
        url: `${apiUrl}/figaro/v1/beneficiary`,
        method: "POST",
        data: requestData,
        headers: authHeaders,
      };

      const response = await axios(config);
      if (response.data.error && response.data.error.code === "InvalidTOTP") {
        toast.error("Hmm, looks like the code you entered was incorrect", {});
      } else if (response.data.error) {
        toast.error(response.data.error.message);
      } else {
        toast.success("Beneficiary archived successfully.");
        setBasicModalOpen(false);
        triggerReload();
      }
    } catch (error) {
      toast.error("Failed to archive beneficiary.");
    }
  }, [totp]);

  return handleArchive;
};

export default useHandleArchive;
