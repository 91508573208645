import { renderBankDetails } from "../../../store/renderBankDetails";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import ArchiveIcon from "@mui/icons-material/Archive";
import { RolesContext } from "../../../store/context/RolesContext";

export function BeneficiaryDetailsModal({
  open,
  handleClose,
  entityDetails,
  loading,
  error,
  onCopy,
  onArchive,
}) {
  const { roles } = useContext(RolesContext);

  const renderContent = () => {
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading details: {error.message}</p>;
    if (!entityDetails) return <p>No Beneficiary Selected</p>;
    if (entityDetails)
      return renderBankDetails(
        entityDetails.details,
        entityDetails?.details.name,
      );

    return <p>No details available.</p>;
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle
        sx={{
          fontWeight: "bold",
          fontSize: "1.2rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {entityDetails?.details?.name
          ? `${entityDetails.details.name} Details`
          : "Details"}
        <div>
          {entityDetails && onCopy && (
            <Tooltip title="Use these details to start creating a new beneficiary">
              <IconButton onClick={onCopy}>
                <ContentCopyIcon color="primary" />
              </IconButton>
            </Tooltip>
          )}
          {entityDetails &&
            onArchive &&
            roles.includes("beneficiary_disable") &&
            entityDetails.state !== "disabled" && (
              <Tooltip title="Archive Beneficiary">
                <IconButton onClick={onArchive}>
                  <ArchiveIcon color="primary" />
                </IconButton>
              </Tooltip>
            )}
        </div>
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: "#f5f5f5", padding: "20px" }}>
        {entityDetails && entityDetails.details && (
          <Box sx={{ my: 2 }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle1">Type: </Typography>
              <Typography variant="body2">
                {entityDetails.details?.type?.charAt(0).toUpperCase() +
                  entityDetails.details?.type?.slice(1)}
              </Typography>
            </Stack>
          </Box>
        )}
        {renderContent()}
      </DialogContent>
    </Dialog>
  );
}

BeneficiaryDetailsModal.propTypes = {
  entityDetails: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onCopy: PropTypes.func,
};
