import { IconSend, IconTrash } from "@tabler/icons-react";
import React, { useContext } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useAuthHeaders } from "../../auth/AuthHeaders";
import ConfigContext from "../../store/context/ConfigContext";
import { RolesContext } from "../../store/context/RolesContext";

const UserRolesSettingsModal = ({
  open,
  onClose,
  user,
  handleSuccess,
  handleError,
}) => {
  const config = useContext(ConfigContext);
  const clientId = sessionStorage.getItem("clientId");
  const authHeaders = useAuthHeaders();

  const { roles } = useContext(RolesContext);

  const onReinviteClick = async (user) => {
    try {
      const response = await axios.post(
        `${config.API_URL}/login/v1/invite`,
        {
          realm: clientId,
          email: user.username,
          meta: { name: user.username }, // user meta doesn't get updated and we don't retrieve it from the API
          force: true,
        },
        {
          headers: authHeaders,
        },
      );
      if (response.data.error) {
        handleError(response.data.error.message);
      } else {
        handleSuccess("User re-invited successfully");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        sessionStorage.clear();
        window.location.href = "/token-expiry";
      }
      console.error("Error sending invitation:", error);
    }
  };

  const onArchiveClick = async (user) => {
    try {
      const response = await axios.post(
        `${config.API_URL}/login/v1/revoke`,
        {
          revokee: user.id,
          realm: clientId,
          role: null,
        },
        {
          headers: authHeaders,
        },
      );
      if (response.data.error) {
        handleError(response.data.error.message);
      } else {
        if (user.enrollment_expiry) {
          handleSuccess("Invitation revoked successfully");
        } else {
          handleSuccess("User archived successfully");
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        sessionStorage.clear();
        window.location.href = "/token-expiry";
      }
      console.error("Error archiving user:", error);
    }
  };

  const ROLE_CONFIG = {
    invite: {
      label: "Re-Invite User",
      icon: (
        <IconSend
          style={{ width: "15px", height: "15px", marginRight: "5px" }}
        />
      ),
      action: () => onReinviteClick(user),
      color: "green",
    },
    revoke: {
      label: "Archive User",
      icon: (
        <IconTrash
          style={{ width: "15px", height: "15px", marginRight: "5px" }}
        />
      ),
      action: () => onArchiveClick(user),
      color: "red",
    },
  };

  if (user.enrollment_expiry) {
    ROLE_CONFIG.revoke.label = "Revoke Invitation";
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ fontWeight: "bold", fontSize: "1.05rem" }}>
        Manage User
      </DialogTitle>
      <DialogContent>
        <Typography>Email</Typography>
        <Typography color={"#697586"}>{user?.username}</Typography>
        <Grid direction="row" container spacing={2.5}>
          {Object.keys(ROLE_CONFIG).map((config) => {
            return (
              roles.includes(config) && (
                <Grid item>
                  <Button
                    sx={{ backgroundColor: ROLE_CONFIG[config].color, mt: 2 }}
                    variant="contained"
                    onClick={() => {
                      ROLE_CONFIG[config].action();
                      onClose();
                    }}
                  >
                    {ROLE_CONFIG[config].icon}
                    {ROLE_CONFIG[config].label}
                  </Button>
                </Grid>
              )
            );
          })}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default UserRolesSettingsModal;
