import React from "react";

// materialUI imports
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { getCurrencySymbol } from "../../store/getFormattedCurrencyAmount";

// ============================|| Custom - Dropdown ||============================ //

const CustomDropdown = ({
  name,
  label,
  value,
  handleChange,
  handleBlur,
  touched,
  errors,
  options,
  maxHeight,
  textSize,
  disabled,
  isCurrencyField,
}) => {
  const [selectedValue, setSelectedValue] = React.useState(value || "");

  React.useEffect(() => {
    setSelectedValue(value || "");
  }, [value]);

  const handleDropdownChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    handleChange(event);
  };

  return (
    <FormControl fullWidth variant="outlined" sx={{ mt: 1 }}>
      <InputLabel sx={{ mt: 0.5 }}>{label}</InputLabel>
      <Select
        name={name}
        value={selectedValue || ""}
        onChange={handleDropdownChange} // Call handleDropdownChange instead of handleChange directly
        onBlur={handleBlur}
        label={label}
        variant="outlined"
        MenuProps={{
          style: {
            maxHeight: maxHeight,
          },
        }}
        sx={{
          minHeight: "62.5px",
          paddingLeft: "5.5px",
          paddingRight: "5.5px",
        }}
        error={touched[name] && Boolean(errors[name])}
        disabled={disabled}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            value={option.value}
            sx={{ fontSize: textSize || "inherit" }}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="start"
              alignItems="center"
              gap="7px"
              cursor="pointer"
            >
              {option.icon && (
                <img
                  src={`${option.icon}`}
                  alt={`${option.label} Icon`}
                  style={{
                    maxHeight: "30px",
                    width: "auto",
                    objectFit: "contain",
                  }}
                />
              )}
              {/* Conditionally include option.value based on isCurrencyField */}
              {isCurrencyField
                ? `${option.value} - ${option.label} ${getCurrencySymbol(option.value)}`
                : option.label}
            </Box>
          </MenuItem>
        ))}
      </Select>

      <FormHelperText error id={`outlined-adornment-${name}-error`}>
        {touched[name] && errors[name] && errors[name]}
      </FormHelperText>
    </FormControl>
  );
};

export default CustomDropdown;
