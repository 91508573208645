import {Typography} from '@mui/material';
import NavGroup from './NavGroup';
import {useContext} from 'react';
import {RolesContext} from "../../../store/context/RolesContext";
import useMenuItems from "../../menu-items";

const MenuList = () => {
  const { roles } = useContext(RolesContext);
  const menuItems = useMenuItems();

  if (roles === undefined || roles.length === 0) {
    return (
        <Typography variant="h4" align="center" sx={{mt:2}}>
        </Typography>
    );
  }

    const navItems = menuItems.map((group) => {
        const filteredChildren = group.children.filter((item) => {
            if (!item.requiredRoles || item.requiredRoles.length === 0) {
                return true;
            }

            //todo:: This needs to be deleted when we migrate the bank stuff - its gross - but it works for now to control the menu items for approvals
            if (item.id === 'approvals') {
                return roles.includes('approve::payment') && !roles.includes('bank::payment');
            }

            if (item.id === 'bankApprovals') {
                return roles.includes('approve::payment') && roles.includes('bank::payment');
            }

            return item.requiredRoles.every((role) => roles.includes(role));
        });

        if (filteredChildren.length === 0) {
            return null;
        }

    return (
        <NavGroup
            key={group.id}
            item={{
              ...group,
              children: filteredChildren,
            }}
        />
    );
  });

  return <>{navItems}</>;
};

export default MenuList;