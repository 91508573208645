import React, {useContext, useState} from 'react';
import axios from 'axios';
import {useAuthHeaders} from '../auth/AuthHeaders';
import CustomFormField from '../ui-component/form-fields/CustomFormField';
import {Box, Button, Grid, Typography} from '@mui/material';
import ConfigContext from '../store/context/ConfigContext';
import * as Yup from 'yup';
import {Form, Formik} from "formik";
import {RolesContext} from "../store/context/RolesContext";
import {useNavigate} from "react-router-dom";
import {formatDateToMonthDayYearWithTime} from "../common/getDate";
import ErrorToast from "ui-component/alerts/ErrorToast";
import SuccessToast from "ui-component/alerts/SuccessToast";

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    name: Yup.string().required('Name is required'),
});

const InviteForm = () => {
    const {roles} = useContext(RolesContext)
    const config = useContext(ConfigContext);
    const authHeaders = useAuthHeaders();
    const clientId = sessionStorage.getItem('clientId');
    const [inviteResponse, setInviteResponse] = useState(null);
    const [invitedUserEmail, setInvitedUserEmail] = useState("");
    const [invitedUserName, setInvitedUserName] = useState("");
    const navigate = useNavigate();
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [revokeInvitation, setRevokeInvitation] = useState(false);

    const handleSuccessToastClose = () => {
        setShowSuccessToast(false);
    };
    const handleErrorToastClose = () => {
        setShowErrorToast(false);
    };

    const handleSubmit = async (values, {resetForm}) => {
        try {
            const response = await axios.post(
                `${config.API_URL}/login/v1/invite`,
                {
                    realm: clientId,
                    email: values.email,
                    meta: {name: values.name}
                },
                {
                    headers: authHeaders,
                }
            );
            if (response.data.error) {
                setShowErrorToast(true);
                setErrorMessage(response.data.error.message);
            } else {
                setInviteResponse(response.data);
                setInvitedUserEmail(values.email);
                setInvitedUserName(values.name);
                resetForm();
                setShowSuccessToast(true);
                setErrorMessage(null);
                setSuccessMessage("Invitation sent successfully");
                setRevokeInvitation(false);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                sessionStorage.clear();
                window.location.href = '/token-expiry';
            }
            console.error('Error sending invitation:', error);
        }
    };

    const handleRefresh = () => {
        setInviteResponse(null);
    };
    const handleGivePermissions = () => {
        navigate('/users', {state: {email: invitedUserEmail}});
    };

    const handleRevokeInvitation = async () => {
        try {
            const response = await axios.post(
                `${config.API_URL}/login/v1/revoke`,
                {
                    "revokee": inviteResponse.reference,
                    "realm": clientId,
                    "role": null
                },
                {
                    headers: authHeaders,
                }
            );
            if (response.data.error) {
                setShowErrorToast(true);
                setErrorMessage(response.data.error.message);
            } else {
                setShowSuccessToast(true);
                setErrorMessage(null);
                setSuccessMessage("Invitation revoked successfully");
                setRevokeInvitation(true);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                sessionStorage.clear();
                window.location.href = '/token-expiry';
                setShowErrorToast(true)
            }
            console.error('Error revoking invitation:', error);
        }
    }

    return (
        <div>
            {revokeInvitation && inviteResponse &&
                <>
                    <Typography variant="h2" gutterBottom sx={{ mb: 3 }}>
                        Successfully revoked {invitedUserName}'s invitation.
                    </Typography>
                    <Typography>Email</Typography>
                    <Typography color={'#697586'} gutterBottom sx={{ mb: 3 }}>
                        {invitedUserEmail}
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleRefresh}>
                                Invite Another User
                            </Button>
                        </Grid>
                    </Grid>
                </>
            }
            {!inviteResponse ? (
                <Formik
                    initialValues={{realm: '', email: '', name: ''}}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, handleChange, handleBlur, touched, errors }) => (
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item sm={6} md={4}>
                                    <CustomFormField
                                        name="name"
                                        label="Name"
                                        value={values.name}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        touched={touched}
                                        errors={errors}
                                    />
                                    <CustomFormField
                                        name="email"
                                        label="Email"
                                        value={values.email}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        touched={touched}
                                        errors={errors}
                                    />
                                </Grid>
                            </Grid>
                            <Button type="submit" variant="contained" color="primary">
                                Send Invitation
                            </Button>
                        </Form>
                    )}
                </Formik>
            ) : (
                <Box>
                    {inviteResponse.expiry && !revokeInvitation && (
                        <>
                            <Typography variant="h2" gutterBottom sx={{ mb: 3 }}>
                                Successfully invited {invitedUserName}.
                            </Typography>
                            <Typography>Email</Typography>
                            <Typography color={'#697586'} gutterBottom sx={{ mb: 3 }}>
                                {invitedUserEmail}
                            </Typography>
                            <Typography variant="h4" gutterBottom sx={{ mb: 3 }}>
                                Expiry: {formatDateToMonthDayYearWithTime(inviteResponse.expiry)}
                            </Typography>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleRefresh}>
                                        Invite Another User
                                    </Button>
                                </Grid>
                                {roles.includes("grant") && (
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleGivePermissions}>
                                            Give User Permissions
                                        </Button>
                                    </Grid>
                                )}
                                {roles.includes("revoke") && (
                                    <Grid item>
                                        <Button
                                            sx={{backgroundColor: 'red'}}
                                            variant="contained"
                                            onClick={handleRevokeInvitation}>
                                            Revoke Invitation
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </>
                    )}
                </Box>
            )}
            <ErrorToast open={showErrorToast} onClose={handleErrorToastClose} message={errorMessage}/>
            <SuccessToast open={showSuccessToast} onClose={handleSuccessToastClose} message={successMessage}/>
        </div>
    );
};

export default InviteForm;