import React, {useState} from 'react';
// materialUI imports
import {FormControl, FormHelperText, InputLabel, OutlinedInput} from '@mui/material';
import {useTheme} from '@mui/material/styles';

// 3rd Party imports
import {Field} from 'formik';

// ============================|| Custom Form Input ||============================ //

const CustomFormField = ({
                             name,
                             label,
                             value,
                             handleChange,
                             handleBlur,
                             touched,
                             errors,
                             disabled = false,
                             maxLength,
                             validateChars = false,
                             invalidCharError = "Invalid character entered",
                             endAdornment
                         }) => {
    const theme = useTheme();
    const [invalidChar, setInvalidChar] = useState(false);

    // Function to check if the maxLength has been reached
    const isMaxLengthReached = () => maxLength !== undefined && value?.length === maxLength;

    // Function to check for valid characters -visa api requirement
    const validateInput = (inputValue) => {
        if (!validateChars) {
            setInvalidChar(false);
            return true;
        }

        const isValidFirstChar = /^[A-Za-z0-9.,/]$/.test(inputValue[0]);
        const isValidOtherChars = /^[-A-Za-z0-9/()., ]*$/.test(inputValue.substring(1));

        if (inputValue.length === 0 || (isValidFirstChar && isValidOtherChars)) {
            setInvalidChar(false);
            return true;
        } else {
            setInvalidChar(true);
            return false;
        }
    };

    return (
        <FormControl fullWidth sx={{...theme.typography.customInput}} error={Boolean(touched[name] && errors[name])}>
            <InputLabel htmlFor={`outlined-adornment-${name}`}>{label}</InputLabel>
            <Field
                as={OutlinedInput}
                id={`outlined-adornment-${name}`}
                type="text"
                name={name}
                label={label}
                value={value}
                onChange={(event) => {
                    const newValue = event.target.value;
                    if (validateInput(newValue)) {
                        handleChange(event);
                    }
                }}
                onBlur={handleBlur}
                autoComplete="random-name-to-break-autoFill"
                disabled={disabled}
                inputProps={{
                    maxLength: maxLength
                }}
                endAdornment={endAdornment}
            />
            {isMaxLengthReached() && (
                <FormHelperText error id={`outlined-adornment-${name}-max-length`}>
                    Max character length reached
                </FormHelperText>
            )}
            <FormHelperText error id={`outlined-adornment-${name}-error`}>
                {touched[name] && errors[name] && errors[name]}
            </FormHelperText>
            {invalidChar && (
                <FormHelperText error>
                    {invalidCharError}
                </FormHelperText>
            )}
        </FormControl>
    );
};

export default CustomFormField;
