import React from "react";
import MainCard from "../ui-component/cards/MainCard";
import SubCard from "ui-component/cards/SubCard";
import { Grid, Typography, List, ListItem } from "@mui/material";

export function CutOffTimes() {
  const open = sessionStorage.getItem("bankOpensAt");
  const close = sessionStorage.getItem("bankClosesAt");
  const timezone = sessionStorage.getItem("timezone");

  return (
    <MainCard title="Cut Off Times">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SubCard>
            <Typography variant="h3">All times are in {timezone}</Typography>
            <List>
              <ListItem>
                <Grid container direction="column">
                  <Grid item>
                    <Typography>Bank Open Time</Typography>
                  </Grid>
                  <Grid item>
                    <Typography color={"#697586"}>{open}</Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem>
                <Grid container direction="column">
                  <Grid item>
                    <Typography>Bank Close Time</Typography>
                  </Grid>
                  <Grid item>
                    <Typography color={"#697586"}>{close}</Typography>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </SubCard>
        </Grid>
      </Grid>
    </MainCard>
  );
}
