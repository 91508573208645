import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { AuthContext } from "../../auth/AuthContext";
import CircularSpinner from "../../ui-component/CircularSpinner";
import { useBeneficiaries } from "../../store/context/EntityContext";
import { useClientAccount } from "../../store/context/ClientAccountContext";
import { RolesContext } from "../../store/context/RolesContext";
import { useNavigate } from "react-router-dom";

const ClientAccountDropDown = () => {
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const realmsData = JSON.parse(sessionStorage.getItem("realms")) || [];
  const { setIsAuthenticated } = useContext(AuthContext);
  const { clientId, updateClient } = useClientAccount();
  const { triggerReload } = useBeneficiaries();
  const { setRolesDynamically } = useContext(RolesContext);
  const navigate = useNavigate();

  const setRolesForClient = (selectedRealm) => {
    const roleNames = selectedRealm.roles
      ? selectedRealm.roles.map((role) => role.name)
      : [];
    sessionStorage.setItem("userRoles", JSON.stringify(roleNames));
    setRolesDynamically(roleNames);
  };

  useEffect(() => {
    if (!initialized) {
      const storedClientId = sessionStorage.getItem("clientId");

      if (storedClientId) {
        const storedRealm = realmsData.find(
          (realm) => realm.id === storedClientId,
        );
        if (storedRealm) {
          console.log("storedRealm", storedRealm);
          sessionStorage.setItem("clientName", storedRealm.name);
          setRolesForClient(storedRealm);
          setIsAuthenticated(true);
          updateClient(storedClientId);
        }
      } else if (realmsData.length === 1) {
        const singleRealm = realmsData[0];
        sessionStorage.setItem("clientName", singleRealm.name);
        sessionStorage.setItem("clientId", singleRealm.id);
        setRolesForClient(singleRealm);
        setIsAuthenticated(true);
        updateClient(singleRealm.id);
      }
      setInitialized(true);
    }
  }, [initialized, realmsData, updateClient, setIsAuthenticated]);

  useEffect(() => {
    if (initialized && clientId && !loading) {
      const storedRealm = realmsData.find((realm) => realm.id === clientId);

      if (storedRealm) {
        setRolesForClient(storedRealm);
      }
    }
  }, []);

  const handleSelectChange = async (event) => {
    const selectedRealmId = event.target.value;
    const selectedRealm = realmsData.find(
      (realm) => realm.id === selectedRealmId,
    );

    if (selectedRealm) {
      setIsAuthenticated(true);
      setLoading(true);
      updateClient(selectedRealm.id);
      sessionStorage.setItem("clientName", selectedRealm.name);
      sessionStorage.setItem("clientId", selectedRealm.id);
      setRolesForClient(selectedRealm);
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulates a delay
      setLoading(false);
      triggerReload();
      navigate("/");
    }
  };

  realmsData.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <>
      {loading ? (
        <Box sx={{ mr: 5 }}>
          <CircularSpinner />
        </Box>
      ) : realmsData.length === 1 ? (
        <Typography sx={{ mr: 2 }}>Welcome, {realmsData[0].name}</Typography>
      ) : (
        <FormControl
          variant="outlined"
          sx={{
            width: "240px",
            mr: 3,
          }}
        >
          <InputLabel id="clientAccount-label">Client Account</InputLabel>
          <Select
            labelId="clientAccount-label"
            id="clientAccount"
            name="clientAccount"
            label="Client Account"
            value={clientId || ""}
            onChange={handleSelectChange}
          >
            {realmsData.map((realm, index) => (
              <MenuItem key={index} value={realm.id}>
                {realm.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default ClientAccountDropDown;
