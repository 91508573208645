// 3rd Party imports
import currency from 'currency.js'
import getSymbolFromCurrency from 'currency-symbol-map'
import {getDecimalPlaces} from "../common/currencyDecimalFormater";

// ==============================|| GET CURRENCY SYMBOL ||============================== //
export const getCurrencySymbol = (currencyValue) => {
    return getSymbolFromCurrency(currencyValue) || "";
};

// ============================|| FORMATTED CURRENCY ||============================ //

const getFormattedCurrencyAmount = (amount, currencyValue) => {
    // Configure currency options
    const currencyOptions = {
        symbol: getCurrencySymbol(currencyValue),
        precision: getDecimalPlaces(currencyValue), // Use getDecimalPlaces to set precision
        pattern: `!#` // Placing symbol before the amount
    };

    return currency(amount, currencyOptions).format();
};

export const getFormattedCurrencyAmountWithName = (amount, currencyValue) => {
    const formattedAmount = getFormattedCurrencyAmount(amount, currencyValue);
    return `${formattedAmount} ${currencyValue}`;
};

export default getFormattedCurrencyAmount;